.navbar {
  padding: 0;
  @include media-query($on-palm) {
    padding: 0.5rem 1rem;
  }
}

.tall-nav {
  padding: 1rem 1rem;
}
