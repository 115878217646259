/* Add your custom styles here */
// .btn-primary{
//     background-color: #1d4367;
//     border-color: #1d4367;
//     color: #ffc00f;
//     &:hover{
//         background-color: #ffc00f;
//         border-color: #ffc00f;
//         color: #1d4367;
//     }
// }
// .bg-primary{
//     background-color: #1d4367 !important
// }